.container {
    display: flex;
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    /* Ensure the container takes up at least the viewport height */
    height: 100%;
}

.main-content {
    padding-bottom: 60px;
    flex: 1;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-bottom: 50px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 300px;
}

.label {
    border: 0px none;
    height: 16px;
    width: 161px;
}

.text-wrapper {
    color: #515761;
    font-family: "Arial-Regular", Helvetica;
    font-size: 16px !important;
    font-weight: 400;
    /* left: 0; */
    letter-spacing: 0;
    line-height: 16px;
    /* position: fixed; */
    top: 0;
    white-space: nowrap;
}

.checkbox {
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.1;
    border: none !important;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    color: #515761;
}

.checkbox input {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: #515761;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #515761;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.checkbox input:focus {
    outline: none; 
    border: 0.15em solid #515761 !important; 
  }

.checkbox input::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em green;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

.checkbox input:checked::before{
    transform: scale(1);
}