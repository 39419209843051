.element-text-field {
  background-color: #ffffff;
  border: 2px solid;
  border-color: #aaaaaa;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
}

.element-text-field .fileInput {
  display: none;
}

.element-text-field label {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-weight: bold;
  color: #767676;
  font-family: "Open Sans-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  /* z-index: 1; */
}

.element-text-field input {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 4px;
  outline: none;
  background: transparent;
}

input:focus {
  border: none !important;
}

.float{
  visibility: hidden;
}
