/* IconButtonGroup.css */

.icon-button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px; /* Set a max width if desired */
  
    .icon-button {
      display: flex;
      padding: 10px;
  
      .icon-button-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
  
        .icon {
          width: 48px; /* Adjust the width and height as needed */
          height: 48px;
        }
  
        .label {
          margin-top: 5px;
        }
      }
    }
  }
  