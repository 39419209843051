.myFooter {
    background-color: #ffffff;
    border-color: #6991cb;
    border-top-style: solid;
    border-top-width: 3px;
    display: flex;
    width: 100%;
    gap: 8px;
    height: 50px;
    position: absolute;
    align-items: center;
    bottom: 0;
  }
  
  .myFooter .frame {
    align-items: flex-start;
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    height:100%;
    padding-left:1.5rem;
  }

  .myFooter .text-wrapper {
    color: #6991cb;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    margin-left: 10px;
    position: relative;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
  }
  