.custom-button {
    background-color: #6991cb;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-end;
  }
  
  .custom-button:hover {
    background-color: #6991cb;
  }
  