.header {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: #6991cb;
  display: flex;
  padding: 16px;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* rules go here */
  .header {
     width: 100%;
  }
}

nav{
  width: 100vw;
}

.navbar {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
}

.left-buttons, .right-buttons {
  display: flex;
  align-items: center;
}

.right-buttons{
  margin-right: 20px;
}


nav ul {
  list-style: none;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 1rem;
  border-right: 1px solid black; 
  padding-right: 1rem;
}

nav li:last-child {
  border-right: none; 
}

nav a {
  text-decoration: none;
  color: #6991cb;
  display: flex;
  align-items: center;
}

nav a:hover {
  color: lightgray;
}